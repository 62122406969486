<div class="elevate-tooltip"
    [class.visible]="showTooltip"
    [style.--size]="size + 'px'"
    [ngx-tooltip]="tooltip">
    @if (label) {
    <label>{{label}}</label>
    }
    <mat-icon>help</mat-icon>
</div>
<ng-template #tooltip>
    <ngx-stackedit [style]="styles" [value]="tipData.content" mode="viewonly"></ngx-stackedit>
</ng-template>
