import { Component, Inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TooltipAllModule, TooltipComponent, TooltipEventArgs } from '@syncfusion/ej2-angular-popups';
import { MatIconModule } from '@angular/material/icon';
import { UserService } from 'src/app/services/user.service';
import { NgIf } from '@angular/common';
import { StackEditorComponent } from 'ngx-stackedit';
import { TooltipDirective } from 'src/app/directives/tooltip.directive';

export enum elevateTips {
    empty = 0,
    eob_app_alloc_requestOwner = 1,
    eob_app_alloc_requestStatus = 2
}


export type TooltipData = { styles?: {}, content: string }
export type TooltipEntries = { [key: string]: TooltipData }

type eTip = {
    Id: string;
    tipText: string;
    style?: string;
}

@Component({
    selector: 'elevate-tooltip',
    templateUrl: './elevate-tooltip.component.html',
    styleUrls: ['./elevate-tooltip.component.scss'],
    imports: [
        NgIf,
        TooltipAllModule,
        TooltipDirective,
        StackEditorComponent,
        MatIconModule
    ],
    standalone: true,
    encapsulation: ViewEncapsulation.None
  })

  export class ElevateTipComponent implements OnInit  {
    @Input() tipTitle?: string; // Backwards compatibility
    @Input() tipData?: TooltipData;
    @Input() label: string = null;
    @Input() size = 12;

    private defaultStyles = {
        width: "500px",
        height: "300px",
        minHeight: "100px",
        maxHeight: "500px",
        overflowY: "auto"
    }
    public styles = "";

    // Links used in original tooltips - we'll relete all of this once the relevant components have their own tooltips.ts files.
    linkDTHostUnits: string = "<a href='https://www.dynatrace.com/support/help/monitoring-consumption/application-and-infrastructure-monitoring?_ga=2.250410246.1676180381.1676134548-2088193884.1674401385#host-unit-hours' target='_blank'><b>Host Unit Hours</b></a>";
    linkDEMUnits: string = "<a href='https://www.dynatrace.com/support/help/monitoring-consumption/digital-experience-monitoring-units' target='_blank'><b>DEM Units</b></a>";
    linkAppSecUnits: string = "<a href='https://www.dynatrace.com/support/help/monitoring-consumption/application-security-units' target='_blank'><b>AppSec ASUs</b></a>";
    linkDTRUM: string = "<a href='https://www.dynatrace.com/support/help/how-to-use-dynatrace/real-user-monitoring' target='_blank'><b>Click Here</b></a>";
    linkDTOAInfra: string = "<a href='https://www.dynatrace.com/support/help/how-to-use-dynatrace/infrastructure-monitoring/hosts/basic-concepts/how-effective-is-infrastructure-monitoring-on-its-own' target='_blank'><b>Click Here</b></a>";
    linkDTOAFullstack: string = "<a href='https://www.dynatrace.com/support/help/technology-support/oneagent-platform-and-capability-support-matrix' target='_blank'><b>Click Here</b></a>";
    linkDTAppSec: string = "<a href='https://www.dynatrace.com/support/help/how-to-use-dynatrace/application-security' target='_blank'><b>Click Here</b></a>";
    imgBigDTLogo: string = "<div style='text-align: center'><img src='./assets/logo/DT.png' height='54px' width='54px'/></div>"
    linkDDUnits: string = "<a href='https://www.dynatrace.com/support/help/monitoring-consumption/davis-data-units' target='_blank'><b>D</b>avis <b>D</b>ata <b>U</b>nits</a>";
    linkDTDDU: string = "<a href='https://www.dynatrace.com/support/help/monitoring-consumption/davis-data-units/log-management-and-analytics' target='_blank'>Click Here</a>";
    linkMSUnits: string = "";
    linkDTMSU: string = "";

    eTips: eTip[] = [
        { Id: "Dynatrace Request Owner", style: "height: 50px;", tipText: "<p>The Request Owner is the User/Group/Org that has been Assigned as Accountable for this Allocation Request. By Default the Creator of the Request is Owner and Accountable.</p>" },
        { Id: "Dynatrace Request Status", tipText: "<h1>New: Estimating</h1><p>This status indicates that the allocation exists for capacity and cost estimation only. Will not show up as a request for EOT consideration.</p>"+
                                                   "<h1>New: Waiting on EOT Response</h1><p>This status places the Dynatrace request in the EOT's queue of new requests, awaiting a response. Once progressed to In Review no other changes will be permitted on the allocation by the App/Platform team except to Withdraw the request</p>"+
                                                   "<h1>In Review: Waiting on EOT Response</h1><p>This status indicates the App/Platform Dynatrace deployment is on hold and wait until the EOT has reviewed the new information submitted.</p>"+
                                                   "<h1>Info Needed: Waiting on App/Platform Response</h1><p>The App/Platform is being considered within a Coverage phase but more information is needed by EOT. A project Task is open to track and collaborate on this request (see Allocation Task for Details).</p>"+
                                                   "<h1>Completed</h1><p>This Allocation request is finalized and cannot be reopen</p>" },
        { Id: "Dynatrace Request Disposition", style: "height: 300px;", tipText: "<h1>Hold Deployment</h1><p>The App/Platform team is ask to hold-off until the EOT can verify capacity, support, provisioning and licensing capacity.</p>"+
                                                        "<h1>Denied</h1><p>The Enterprise request for deployment has been declined, may be due to support, licensing, capacity, capability or a variety of other reasons.</p>"+
                                                        "<h1>Appoved</h1><p>This status indicates the App/Platform Dynatrace deployment and the progress of that approval. See Application Tasks to view related activities</p>"+
                                                        "<h1>Withdrawn</h1><p>The Request is over and has been Ended by Either the EOT or App/Platform team. Either EOT and/or App/Platform team can re-open the request, however, it will be immediately placed back into New status.</p>" },
        { Id: "Allocation Cost Center", style: "height: 100px;", tipText: "<p>The Cost Center selected in the Allocation only serves to perform Cost Estimation Allocations. Cost Centers in Elevate have a default Rate Group by which they calculate the configured chargeback costs. If no Cost Center is selected then Cost Estimations will be excluded from the allocation.</p>" },

        { Id: "Provider", style: "height: 125px;", tipText: "<p>An Instrumental part of Elevates Observability Blueprinting is capturing all of the Infrastructure providers that will are involved in Application/Platform deployments. Providers refer to any company that is responsible for providing the physical/virtual infrastructure to be monitored. For example the common cloud providers that will often show up here, like, Azure, AWS, Google, etc. The Enterprise should also appear to indicate all on-premise hardware and/or internal private clouds.</p>" },
        { Id: "Region", style: "height: 150px;", tipText: "<p>For every provider that appears in the Blueprint, they should all have one or more regions where the infrastructure is located. Not only should we be consistent with how Cloud architectures are captured but it dramatically helps in organizing observed entities and telemetry targets. The regions should exactly match the regions for each of their respective cloud providers and if the provider is the Enterprise itself those regions should either be aligned with exactly how the Enterprise codes them  and, if not then they should be aligned with one of the common cloud providers preferred by the Enterprise.</p>" },
        { Id: "Location/DataCenter", style: "height: 200px;", tipText: "<p>These are used interchangeably in Elevate. The reason its identified either or, is because many Enterprises have large numbers of branch offices (locations) that may be monitored. However, more commonly these will be data centers where large shared infrastructure is located. This is where the Apps/Platforms are deployed and Dynatrace Agents reside. When defining the data centers for common Cloud providers these are interchangeable with what Cloud Providers refer to as Availability Zones. Therefore, when defining a data center for a cloud provider it should exactly match the name of the Availability Zone. Often times, Enterprises will assign their locations / data centers with a short name or code in which case these should match theirs for clarity within reporting and the interface.</p>" },
        { Id: "Zone", style: "height: 100px;", tipText: "<p>A subgrouping of IT assets that restrict access to other IT assets. The most common definition being a domain of a network that represents the logical hierarchy of subnetworks such as DMZ, Secure, and Internal. This can also extend to use cases in the public cloud where specific accounts/subscriptions are limited by security constraints.</p>" },
        { Id: "Dynatrace Needed By", style: "height: 125px;", tipText: "<p>This is the date that the App/Platform team is requesting to have Dynatrace deployed for their App/Platform Logical Environment and Location. While this may be the desired date the EOT may require more time and the team should confirm/negotiate the actual viable dates with the Enterprise. The EOT is ultimately capacity planning for a group of applications for a specific deployment Goal Date</p>" },
        { Id: "Allocation Need Ends", style: "height: 100px;", tipText: "<p>Allocation End Dates are typically chosen under a variety of reasons. The App/Platform may be migrating to a different location/data center, cloud provider, or major changes need to be made and this is used to expire this allocation and request a completely new one for this location and environment.</p>" },
        { Id: "Footprint", style: "height: 150px;", tipText: "<p>The App/Platform may have entirely different Footprints that they deploy to. For example an Application that have a legacy on-premise version that deploys to bare-metal boxes may also be migrating their application to a hybrid or fully-hosted version in the Cloud. These type of scenarios can be managed using Footprints as a way to divide the Application's deployment schemes and monitoring differently while maintaining the fact that it's still the same application.</p>" },
        { Id: "Logical Environment", style: "height: 300px;", tipText: "<p>An Applications Logical Environment typically depicts the primary purpose of the App/Platform deployed binaries/code. While each Application:Footprint may have a wide variety of Logical Environments for which they deploy, it is best to standardize on a more finite list when Managing observability and "+
                                                                        "requesting Dynatrace coverage. While Elevate accomodates the ability to capture each applications Logical Environment names exactly it is better to standarize on a more finite list of "+
                                                                        "PROD and NON-PROD environments. It's also very important to maintain a very distinct separation between PROD and NONPROD environments since the EOT will often send Dynatrace Agent Data to "+
                                                                        "different environments depending on PROD and NON-PROD. We also do not want Dynatrace Davis AI blending traffic and activity for the two when evaluating baselines, performance and Problems.<br><br>"+
                                                                        "If none of the Logical Environments listed properly represent the need then a new one can be created by navigating into EOB->Applications->(Choose the Footprint) and finally under the Footprint profile it can be added accordingly.</p>" },
        { Id: "Assignments", tipText: "Assignments on Projects only have an effect if the Project has been set to a Privacy of Restricted." },
        { Id: "Test", tipText: "Some test data" },
        { Id: "Dynatrace Requests", tipText: "Any Enterprise Application or Platform that requires consumption of Dynatrace capabilities should ultimately have Dynatrace Allocation Requests entered which helps the Enterprise build a certain budget of each Dynatrace daily license consumption type that the App/Platform needs or will require.<br><br>The Dynatrace allocation requests are used to manage all Enterprise requests and observability demands. This demand drives License expectations, provisioning requiremens, helps prioritize needs and forecast costs.<br><br>Using Elevates ability to manage such allocations ensures that License purchases vs use and consumption are prioritized and targeted for the intended systems, environments and/or data centers. It also helps ensure Dynatrace hardware provisioning is in line with expectations."+
                                                " Observability Allocations are performed within the EOB for Enterprise Applications and/or Enterprise Platforms. Large volumes of allocations can be loaded using the EOB Import."},
        { Id: "Dynatrace Claims", style: "height: 475px;", tipText: "These are a list of Dynatrace Environments that can be searched to obtain entities related to the given Application and/or Platform. The results of the combined searches will be used to verify that the Application and/or Platform team can claim what is found in their Dynatrace environment(s) are accurately represented. " +
                                           "<br><br>To setup an Observability Claim, one simply enters a Dynatrace Environment, the Dynatrace search strategy, ie Management Zone and/or DQL that maintains the rules of the Entities to be identified, along with some attributes and metadata mining rules. "+
                                           "Elevate will provide a verification and validation process. This is very important to help ensure quality of coverage for each Application or Platform. We will also rely on these claims for many other automated processes within Elevate. The verified claims will be used to determine:<br><br>"+
                                            "  - Captured daily consumption trends vs forecasted <br>"+
                                            "  - Chargeback Accounting and Calculations <br>"+
                                            "  - Pre-requisite for determining Observability Implementation Scores <br>"+
                                            "  - Accuracy of Enterprise Coverage/Configuration vs Requested Allocations <br><br>" +
                                            "If it’s determined that certain entities are simply missing from the Dynatrace management zone, Elevate will provide a link directly to the Dynatrace management zone that needs to be modified. Since Davis AI delivers problems to the Management Zone we want to be sure all of the relevant changes occur in Dynatrace to fix what is seen. Therefore, ensure the Dynatrace management zone rules are accurate otherwise adjust them in Dynatrace in order to verify those changes in Elevate’ s Observability Claims screen."},
        { Id: "OneAgent Infra Only", style: "height: 175px;", tipText: "Dynatrace <b>O</b>ne<b>A</b>gent Infrastructure Monitoring Host Unit Capactiy Request. This is the expected AVG "+this.linkDTHostUnits+" operating in INFRA_ONLY throughout a single day, for this App/Platform footprint, within it's Logical deployment Environment and Location. To understand Dynatrace Infrastructure Only monitoring " + this.linkDTOAInfra + '<br><br>' + this.imgBigDTLogo },
        { Id: "OneAgent Fullstack", style: "height: 175px;", tipText: "Dynatrace <b>O</b>ne<b>A</b>gent Fullstack Monitoring Host Unit Capacity Request. This is the expected AVG "+this.linkDTHostUnits+" operating in FULLSTACK throughout a single day, for this App/Platform footprint, within it's Logical deployment Environment and Location. To understand Dynatrace Fullstack monitoring capabilities " + this.linkDTOAFullstack + '<br><br>' + this.imgBigDTLogo },
        { Id: "Dynatrace DEM", style: "height: 175px;", tipText: "Dynatrace <b>D</b>igital <b>E</b>xperience <b>M</b>onitoring Daily Capacity Request. This is the expected AVG "+this.linkDEMUnits+" estimated throughout a single day, for this App/Platform footprint, within it's Logical deployment Environment and Location. To also understand Dynatrace's <b>R</b>eal <b>U</b>ser <b>M</b>onitoring capabilities " + this.linkDTRUM + '<br><br>' + this.imgBigDTLogo },
        { Id: "Dynatrace AppSec", style: "height: 175px;", tipText: "Dynatrace Application Security Monitoring Daily Capacity Request. This is the expected AVG DAILY "+this.linkAppSecUnits+" estimated throughout a single day, for this App/Platform footprint, within it's Logical deployment Environment and Location. To better understand Dynatrace's Application Security capabilities " + this.linkDTAppSec + '<br><br>' + this.imgBigDTLogo },
        { Id: "Dynatrace DDUs", style: "height: 175px;", tipText: "Dynatrace Davis Data Units Request. This is the expected AVG "+this.linkDDUnits+" estimated throughout a single day, for this App/Platform footprint, within it's Logical deployment Environment and Location. To better understand Dynatrace's DDU for Log Management/Ingestion " + this.linkDTDDU + '<br><br>' + this.imgBigDTLogo },
        { Id: "Dynatrace MSUs", style: "height: 175px;", tipText: "Dynatrace Mainframe Volume Request. This is the expected AVG "+this.linkMSUnits+" estimated throughout a single day, for this App/Platform footprint, within it's Logical deployment Environment and Location. To better understand Dynatrace Data Units " + this.linkDTMSU + '<br><br>' + this.imgBigDTLogo }
    ];

    showTooltip = false;

    private _sub;
    constructor(private user: UserService) { }

    ngOnInit() {
        // Temporary, for backwards compatibility
        if (this.tipTitle) {
            const eTip = this.eTips.find(tip => tip.Id == this.tipTitle);
            if (eTip)
                this.tipData = {
                    styles: eTip.style,
                    content: eTip.tipText
                }
        }

        this._sub = this.user.preferences$.subscribe(p => {
            this.showTooltip = p.general.tooltips != "omit" && !!this.tipData;
        });

        this.styles = Object
            .entries({ ...this.defaultStyles, ...this.tipData?.styles })
            .map(([key, value]) => this.toCSSStyleName(key) + ": " + value).join("; ");
    }

    ngOnDestroy() {
        this._sub?.unsubscribe();
    }

    toCSSStyleName(styleName: string): string {
        const cssName: string[] = [];
        for (let i = 0; i < styleName.length; i++) {
            if (i !== 0 && styleName[i].toUpperCase() === styleName[i]) cssName.push('-');
            cssName.push(styleName[i].toLowerCase());
        }
        return cssName.join("");
    }
}
